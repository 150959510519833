import { Icon, Typography } from '@shc/ui'
import clsx from 'clsx'
import type { TypographyProps } from '@shc/ui'

type RatingProps = {
  variant?: TypographyProps['variant']
  value: number
  className?: string
}

const Ratings = ({ variant = 'h5', value, className }: RatingProps) => {
  return (
    <div className={clsx('flex gap-x-1.5 items-center', className)}>
      <Icon icon="star" className="text-warning" size="xs" />
      <Typography variant={variant} as="p">
        {typeof value === 'number' ? Number(value).toFixed(1) : 0}
      </Typography>
    </div>
  )
}

export default Ratings
