'use client' // this is increasing the bundle size
import { usePathname } from '@i18n/navigation'
import NavBar from '@components/nav-bar'
import menuItemMapper from '@lib/mappers/menu-item-mapper'
import type { TypeNavBarBlockFields } from '@lib/generated-types'
import slugify from '@sindresorhus/slugify'

const NavBarBlock = ({ sectionLink, menuItems, alignment }: TypeNavBarBlockFields) => {
  return (
    <NavBar
      id={sectionLink === undefined ? undefined : slugify(sectionLink)}
      menu={menuItems.map(menuItemMapper)}
      alignment={alignment}
      activePathname={usePathname()}
    />
  )
}

export default NavBarBlock
