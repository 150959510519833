import { setSchedulingType } from '@lib/utilities/provider-utilities'
import type { TypeProviderPublic } from '@lib/provider-types'
import type { ContentfulImagesAPI, ImageSizes } from '@components/contentful-image'
import ContentfulImage from '@components/contentful-image'
import Ratings from '@components/ratings'
import { aspectRatioMap } from '@lib/token-maps'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import isInteger from 'lodash/isInteger'
import { dateFirstAvailableFadPromo } from '@lib/utilities/date-utilities'
import { Icon, Typography, Card } from '@shc/ui'
import { AnalyticsLink } from '@components/analytics'
import NextLink from 'next/link'
import { site } from '@lib/constants'

const extras = generateBaseMediaQWidths(4)
const focalPoint = 'Face'
let aspectRatioVal = aspectRatioMap['1:1']
const imageSizes: ImageSizes[] | undefined = extras?.map((value) => {
  return {
    mediaQuery: value.mediaQuery,
    params: {
      w: value.w,
      h: Math.ceil(value.w / aspectRatioVal),
      fit: 'fill',
      f: focalPoint?.toLowerCase() as Lowercase<NonNullable<ContentfulImagesAPI['f']>>,
    },
  }
})

export const formatFirstAvailableForPromo = (epoch: number, type: string) => {
  const zuluString = new Date(epoch).toISOString()
  const apptDate = dateFirstAvailableFadPromo(zuluString)

  if (type === 'virtual') {
    return `${apptDate} (virtual)`
  } else {
    return `${apptDate} (in person)`
  }
}

export interface ProviderPromoCardProps {
  promoProvider: TypeProviderPublic & {
    first_available_appt_office: number
    first_available_appt_virtual: number
  }
  displayAppt: boolean
}

const ProviderPromoCard = ({ promoProvider, displayAppt }: ProviderPromoCardProps) => {
  const providerPhotoUrl = promoProvider.photo_url?.replace(/^https?:/i, '')
  const schedulingType = setSchedulingType(promoProvider)
  const isVirtualDateNumeric = isInteger(promoProvider.first_available_appt_virtual)
  const isOfficeDateNumeric = isInteger(promoProvider.first_available_appt_office)

  return (
    <Card className="flex flex-col w-full h-full space-y-4" variant="outlined" padding="md">
      <div>
        <div className="-mt-[69px] overflow-hidden object-cover w-[90px] h-[90px] border-2 border-gray-100 rounded-full duration-200 bg-gray-100">
          <AnalyticsLink
            as={NextLink}
            href={`/doctors/${promoProvider.slug}`}
            snowplow={{
              contexts: [
                {
                  name: 'component',
                  data: {
                    component_text: promoProvider.name_formatted_with_degrees ?? '',
                    component_url: `${site.url}/doctors/${promoProvider.slug}`,
                  },
                },
              ],
            }}>
            <ContentfulImage
              src={providerPhotoUrl}
              imageSizes={imageSizes}
              alt="Provider Image"
              className="hover:scale-[1.03] ease-in-out duration-300"
            />
          </AnalyticsLink>
        </div>
        <Typography className="text-primary font-bold hover:text-primary-800 hover:underline mt-4">
          <AnalyticsLink
            as={NextLink}
            href={`/doctors/${promoProvider.slug}`}
            className="block h-full"
            snowplow={{
              contexts: [
                {
                  name: 'component',
                  data: {
                    component_text: promoProvider.name_formatted_with_degrees ?? '',
                    component_url: `${site.url}/doctors/${promoProvider.slug}`,
                  },
                },
              ],
            }}>
            {promoProvider.name_formatted_with_degrees}
          </AnalyticsLink>
        </Typography>
        {promoProvider.places?.length ? (
          <div className="flex text-sm">{promoProvider.places[0].city}</div>
        ) : (
          <div className="text-sm">&nbsp;</div>
        )}
        {promoProvider.review_rating &&
        promoProvider.review_count &&
        promoProvider.review_count > 29 ? (
          <Ratings value={promoProvider.review_rating} variant="small-body" />
        ) : (
          <div className="text-sm">&nbsp;</div>
        )}
      </div>
      {displayAppt ? (
        <div className="flex flex-col gap-1 justify-start">
          {schedulingType === 'Online' ? (
            <div>
              {isVirtualDateNumeric || isOfficeDateNumeric ? (
                <div className="flex flex-col gap-y-1">
                  <div className="flex text-sm font-semibold">First available</div>

                  {/* Virtual */}
                  {isVirtualDateNumeric && (
                    <div
                      className="flex flex-row text-sm"
                      data-testid="first-available-virtual-div">
                      <Icon className="w-3.5 pr-1.5 mt-1" icon="video" />
                      {formatFirstAvailableForPromo(
                        promoProvider.first_available_appt_virtual,
                        'virtual'
                      )}
                    </div>
                  )}

                  {/* In Person */}
                  {isOfficeDateNumeric && (
                    <div className="flex flex-row text-sm" data-testid="first-available-person-div">
                      <Icon className="w-3.5 pr-1.5 mt-1" icon="buildings" />
                      {formatFirstAvailableForPromo(
                        promoProvider.first_available_appt_office,
                        'in person'
                      )}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ) : (
            <>
              <Typography variant="small-body-semibold" as="h6" align="left">
                Appointments available
              </Typography>

              <div className="text-sm">
                {promoProvider.is_providing_virtual_visits
                  ? 'In-person and virtual visits'
                  : 'In-person'}
              </div>
            </>
          )}
        </div>
      ) : null}
    </Card>
  )
}

export default ProviderPromoCard
