'use client'

import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { ptMap, pbMap, cardWidthMap, columnsMaxCardMap, colWidthCenterMap } from '@lib/token-maps'
import type { ContentfulNewsArticles } from '@lib/types'
import Analytics from '@components/analytics'
import Card from '@components/card'
import { type TypeHealthNewsStoriesBlockFields } from '@lib/generated-types'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import CaptionedMedia from '@cms/components/captioned-media'
import { contentTypeBasePathMap } from '@lib/constants'
import slugify from '@sindresorhus/slugify'
import { Container } from '@shc/ui'
import ClientPagination from '@components/client-pagination'
import { useSearchParams } from 'next/navigation'
import { getNewsArticlesAction } from '@lib/services/actions/news-articles-service'

const HealthNewsStoriesBlock = ({
  sectionLink,
  topics,
  authors,
  articlesToExclude,
  cardsMax,
  columnsMax,
  width,
  isPaginated,
  paddingTop,
  paddingBottom,
}: TypeHealthNewsStoriesBlockFields) => {
  const searchParams = useSearchParams()
  const currentPageNumber = Number(searchParams.get('page')) || 1

  const [relatedNews, setRelatedNews] = useState<ContentfulNewsArticles | null>(null)

  useEffect(() => {
    const getNews = async () => {
      const articles = await getNewsArticlesAction(
        topics?.length ? topics.map((topic) => topic.sys.id) : undefined,
        articlesToExclude?.length
          ? articlesToExclude.map((article) => article.fields.slug)
          : undefined,
        authors?.length ? authors.map((author) => author.sys.id) : undefined,
        cardsMax,
        (currentPageNumber - 1) * cardsMax
      )
      setRelatedNews(articles)
    }

    getNews()
  }, [currentPageNumber])

  if (relatedNews) {
    return (
      <Container
        id={sectionLink === undefined ? undefined : slugify(sectionLink)}
        className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
        data-block="healthNewsStoriesBlock">
        <div
          className={clsx(
            colWidthCenterMap[width],
            'flex flex-row flex-wrap gap-x-5 gap-y-8 lg:gap-y-12'
          )}>
          {!!relatedNews.data.length &&
            relatedNews.data.map(({ fields }, index) => {
              return (
                <Analytics
                  key={index}
                  snowplow={{
                    contexts: [
                      {
                        name: 'component',
                        data: {
                          component_text: fields.title,
                          component_url: contentTypeBasePathMap['newsArticle'] + fields.slug,
                        },
                      },
                    ],
                  }}>
                  <Card
                    title={fields.title}
                    summary={fields.summary}
                    image={
                      <CaptionedMedia
                        {...fields.image.fields}
                        aspectRatio="16:9"
                        className="rounded overflow-hidden"
                        imageClassName={clsx(
                          'rounded',
                          'scale-100',
                          'duration-200',
                          'group-hover:scale-105',
                          'group-hover:origin-center',
                          'group-hover:duration-200'
                        )}
                        extras={generateBaseMediaQWidths(4)}
                        showCaption={false}
                      />
                    }
                    link={contentTypeBasePathMap['newsArticle'] + fields.slug}
                    className={clsx('group', cardWidthMap[columnsMaxCardMap[columnsMax]])}
                  />
                </Analytics>
              )
            })}
        </div>
        {isPaginated && relatedNews.total > cardsMax && (
          <ClientPagination
            currentPageNumber={currentPageNumber}
            total={relatedNews.total}
            pageSize={cardsMax}
            scrollTo={slugify(sectionLink ?? '')}
            className="col-span-12 pt-12 lg:pt-15"
          />
        )}
      </Container>
    )
  }
}

export default HealthNewsStoriesBlock
